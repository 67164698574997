import React from "react";

import styles from "./MainHeader.module.scss";

const MainHeader: React.FC = () => {
  return (
    <header className={styles.mainHeader}>
      <svg className={styles.mainHeaderLogo}>
        <use href="/icons/sp-logo.svg#sp-logo-forest"></use>
      </svg>
    </header>
  );
};

export default MainHeader;
