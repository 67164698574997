import { FormError, SignUpFormData } from "@types";

export type SignUpFormFields = {
  [key: string]: FormError;
};
type OnBlurIncubetaType = {
  e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>;
  formData: SignUpFormFields | SignUpFormData;
};
export const handleOnBlurIncubeta = ({ e, formData }: OnBlurIncubetaType) => {
  if ("target" in e) {
    const { name } = e["target"];
    const updatedState = { ...formData };

    const mixPanelData = {
      sp_validation_message: "",
      sp_field: name,
    };

    setTimeout(
      () => {
        const validationData = updatedState[name].validation;
        mixPanelData.sp_validation_message =
          validationData?.message || "success";
        window.dataLayer.push({
          event: "sign_up_fields",
          field_name: mixPanelData.sp_field,
          field_status: mixPanelData.sp_validation_message,
        });
      },
      name === "email" || name === "user_name" ? 4000 : 1000
    );
  }
};
