import React from "react";
import { graphql, PageProps } from "gatsby";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { YoastSEOType } from "../../assets/Types";

import { MainHeader } from "../../components/Headers";
import SimpleDefaultLayout from "../../layouts/SimpleDefaultLayout";
import TextContentContainer from "../../containers/TextContentContainer/TextContentContainer";
import { deIndexSeo } from "../../utils/deIndexSeo";

type ClientFacingTextPageType = {
  wpPage: {
    title: string;
    content: string;
    seo: YoastSEOType;
    template: {
      textPg: {
        textTitle: string;
      };
    };
  };
};

const ClientFacingTextPage: React.FC<PageProps<ClientFacingTextPageType>> = ({
  data,
}) => {
  const {
    wpPage: {
      content,
      seo,
      template: { textPg },
    },
  } = data;

  return (
    <SimpleDefaultLayout>
      {seo && (
        <SEO post={deIndexSeo(data.wpPage as unknown as Queries.WpPage)} />
      )}
      <MainHeader />

      {content && (
        <TextContentContainer
          content={content}
          textTitle={textPg.textTitle}
          withLargePb
        />
      )}
    </SimpleDefaultLayout>
  );
};

export default ClientFacingTextPage;

export const query = graphql`
  query ClientFacingTextTemplateQuery($id: String) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      id
      content
      template {
        ... on WpClientFacingTextTemplate {
          textPg {
            textTitle
          }
        }
      }
    }
  }
`;
