import { FormError, FormMixPanel, FormValue, SignUpFormData } from "@types";
import React from "react";
import { toSeconds } from "../toSeconds";
import { SIGNUP_SUCCESSFUL } from "../../constants/signupSuccessful";
import {
  handleOnBlurIncubeta,
  SignUpFormFields,
} from "../incubetaEvents/signupFormEvent";
import { getLocalStorageItem } from "../LocalStorage";

export type PageStateType = {
  timeStart: number;
  timeEnd: number;
};

type OnBlurMixpanelType = {
  e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>;
  formData: SignUpFormData;
  setFormData: React.Dispatch<React.SetStateAction<SignUpFormData>>;
};

type OnBlurLiveMixpanelType = {
  e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>;
  formData: FormValue & FormMixPanel;
  formDataError: FormError;
  setFormData: React.Dispatch<React.SetStateAction<FormValue & FormMixPanel>>;
  setFormDataError: React.Dispatch<React.SetStateAction<FormError>>;
};

export const handleBeforeunLoad = (pageState: PageStateType) => {
  const mixpanelData = {
    sp_time: 0,
    $current_url: window.location.href,
  };

  pageState = {
    ...pageState,
    timeEnd: Date.now(),
  };

  mixpanelData.sp_time = toSeconds(pageState.timeEnd - pageState.timeStart);

  if (!JSON.parse(getLocalStorageItem(SIGNUP_SUCCESSFUL))) {
    window.trackMixpanelEvent("user: sign up abandoned");
  }
  window.trackMixpanelEvent("user: sign up time spent", mixpanelData);
};

export const handleOnBlurMixpanel = ({
  e,
  formData,
  setFormData,
}: OnBlurMixpanelType) => {
  if ("target" in e) {
    const { name } = e["target"];
    let updatedState = { ...formData };

    if (updatedState[name].isUpdated) {
      const mixPanelData = {
        sp_error: null,
        sp_time: 0,
        sp_field: name,
        $current_url: window.location.href,
      };

      setFormData(prevState => {
        updatedState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            timeOut: Date.now(),
            isUpdated: false,
          },
        };

        mixPanelData.sp_error = updatedState[name].validation.hasError;

        updatedState[name].timeTotal = toSeconds(
          updatedState[name].timeOut - updatedState[name].timeIn
        );

        mixPanelData.sp_time = updatedState[name].timeTotal;

        return prevState;
      });

      // validation object is not updated for phone_number
      if (updatedState[name].validation?.hasError) {
        formData[name].validation = updatedState[name].validation;
      }

      handleOnBlurIncubeta({ e, formData });

      setTimeout(
        () => {
          mixPanelData.sp_error = updatedState[name].validation?.hasError;

          window.trackMixpanelEvent(
            "user: sign up field updated",
            mixPanelData
          );
        },
        name === "email" || name === "user_name" ? 4000 : 1000
      );
    }
  }
};

export const handleOnBlurLiveMixpanel = ({
  e,
  formData,
  formDataError,
  setFormData,
  setFormDataError,
}: OnBlurLiveMixpanelType) => {
  if ("target" in e) {
    const { name } = e["target"];

    if (formData.isUpdated && name === "email") {
      let updatedFormData = {
        ...formData,
        timeOut: Date.now(),
        isUpdated: false,
      };
      let updatedErrorState = { ...formDataError };

      const mixPanelData = {
        sp_error: null,
        sp_time: 0,
        sp_field: name,
        $current_url: window.location.href,
      };

      const updatedTimeTotal = toSeconds(
        updatedFormData.timeOut - updatedFormData.timeIn
      );

      updatedFormData.timeTotal = updatedTimeTotal;

      setFormData(prevState => ({
        ...prevState,
        ...updatedFormData,
      }));

      mixPanelData.sp_time = updatedFormData.timeTotal;

      setFormDataError(prevState => {
        updatedErrorState = {
          ...prevState,
        };

        return updatedErrorState;
      });

      const validationData = updatedErrorState.validation;
      const signUpformData: SignUpFormFields = {
        [name]: {
          validation: {
            hasError: validationData.hasError,
            message: validationData.message,
          },
        },
      };
      handleOnBlurIncubeta({ e, formData: signUpformData });

      setTimeout(() => {
        mixPanelData.sp_error = updatedErrorState.validation.hasError;

        window.trackMixpanelEvent("user: sign up field updated", mixPanelData);
      }, 1000);
    }
  }
};
